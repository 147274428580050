import './index.scss';

const Landing = () => {
	return (
		<section id="landing" className="wrapper">
			<div className="content">
				<h1 
					className="otsikko" 
					id='landing-head'
					data-aos="fade-up"
					data-aos-delay="250"
					data-aos-duration='750'
					data-aos-easing="ease"
				>
					Rakennusrestaurointi Taltta ja Vasara Oy
				</h1>

				<p
					className="teksti"
					data-aos="fade-up"
					data-aos-delay="500"
					data-aos-duration='750'
					data-aos-easing="ease"
				>
					Rakennusrestaurointi Taltta ja Vasara Oy tarjoaa laadukkaita rakennusten restaurointi- ja entisöintipalveluita Pohjois-Suomen ja Oulun alueella.
				</p>

				<div
					className="landing-buttons"
					data-aos="fade-up"
					data-aos-delay="750"
					data-aos-duration='750'
					data-aos-easing="ease"
				>
					<a 
						href='#palvelut'
						rel='noreferrer noopener nofollow'
						className='btn btn_red'
					>
						Lue Lisää
					</a>

					<a 
						href='#yhteystiedot'
						rel='noreferrer noopener nofollow'
						className='btn'
					>
						Ota Yhteyttä
					</a>
				</div>
			</div>

			<img
				src="./images/home_landingC.jpg"
				alt="Background"
				className="landing-bg"
				loading='eager'
				data-aos='zoom-out'
				data-aos-easing='ease'
			/>

			<div className="bg-overlay" />
		</section>
	);
}

export default Landing;