import { Link } from "react-router-dom";

import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPhone, FaFacebook } from "react-icons/fa6";
import { IoLocationSharp } from "react-icons/io5";

import './footer.scss';

const Footer = () => {
	return (
		<footer id="footer" className='wrapper'>
			<div className="content">
				<div className="footer-top">
					<div className="footer-grid">
						<div className="footer-item">
							<img
								src="./images/logo.svg"
								alt="Logo"
								className="footer-logo"
								loading='lazy'
							/>

							<p className="teksti intro-teksti">
								Rakennusten restaurointi- ja entisöintipalvelut Pohjois-Suomen alueella vuodesta 2016.
							</p>
						</div>

						<div className="footer-item">
							<h4 className="teksti">
								Navigointi
							</h4>

							<a
								href="/#landing"
								className="footer-link teksti"
								rel='noreferrer noopener nofollow'
							>
								Koti
							</a>

							<a
								href="/#palvelut"
								className="footer-link teksti"
								rel='noreferrer noopener nofollow'
							>
								Palvelut
							</a>	

							<a
								href="/#galleria"
								className="footer-link teksti"
								rel='noreferrer noopener nofollow'
							>
								Kuvagalleria
							</a>					
						</div>

						<div className="footer-item">
							<h4 className="teksti">
								Yhteystiedot
							</h4>

							<a
								className="teksti footer-link"
								href='mailto:konttori@talttajavasara.fi'
								rel='noreferrer noopener nofollow'
								target='_blank'
							>
								<BsFillEnvelopeFill className="f-icon" />

								<span>konttori@talttajavasara.fi</span>	
							</a>

							<a
								className="teksti footer-link"
								href='tel:+358407579969'
								rel='noreferrer noopener nofollow'
								target='_blank'
							>
								<FaPhone className="f-icon" />

								<span>+358 40 7579 969</span>	
							</a>

							<p className="teksti">
								<IoLocationSharp className="f-icon" />

								<span>Syrjäntie 10, 90820 Kello</span>
							</p>
						</div>

						<div className="footer-item">
							<h4 className="teksti">
								Sosiaalinen media
							</h4>

							<a
								className="teksti footer-link"
								href='https://www.facebook.com/rakennusrestaurointi/'
								rel='noreferrer noopener nofollow'
								target='_blank'
							>
								<FaFacebook className="f-icon" />

								<span>Taltta ja Vasara Oy</span>	
							</a>
						</div>
					</div>
				</div>

				<div className="footer-bottom">
					<div className="footer-grid">
						<div className="footer-item">
							<p className="teksti">
								Copyright &copy; Taltta ja Vasara Oy 
							</p>

							<p className="teksti">
								Kaikki oikeudet pidätetään
							</p>
						</div>

						<ul className="footer-list">
							<li className="list-item">
								<Link
									to='/käyttöehdot'
									rel="noreferrer noopener nofollow"
									className="list-item"
								>
									Käyttöehdot
								</Link>
							</li>

							<li className="list-item">
								<Link
									to='/tietosuoja'
									rel="noreferrer noopener nofollow"
									className="list-item"
								>
									Tietosuojaseloste
								</Link>
							</li>

							<li className="list-item">
								<Link
									to='/yksityisyyskäytäntö'
									rel="noreferrer noopener nofollow"
									className="list-item"
								>
									Yksityisyyskäytäntö
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;