import './galleria.scss'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';

const Galleria = () => {
  //Asetukset Kuvagalleria komponentille
    const settings = {
        className: "center",
        centerMode: true,
        dots: true,
        appendDots: (dots: any) => ( //Customoidut navigaatio pallot
            <div
              style={{
                backgroundColor: "transparent",
                borderRadius: "50%",
                padding: "10px"
              }}
            >
                <ul className='dots'>
                    {dots}
                </ul>
            </div>
          ),
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        speed: 1500,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]
    };

    return (
        <section id="galleria" className="wrapper">
            <div className="content">
                <h2 className='otsikko' id='otsikko-galleria'>Kuvagalleria</h2>
            <div className="slider-container" data-aos= 'fade-up' data-aos-offset="250">
              {/* KuvaGalleria */}
            <Slider {...settings}>
                <div className='img-body'>
                    <img src="./images/galleria-kuva1C.jpg" alt='kuva1' className='image'/>
                </div>
                <div className='img-body'>
                    <img src="./images/galleria-kuva2C.jpg" alt='kuva2' className='image'/>
                </div>
                <div className='img-body'>
                    <img src="./images/galleria-kuva3C.jpg" alt='kuva3' className='image'/>
                </div >
                <div className='img-body'>
                    <img src="./images/galleria-kuva4C.jpg" alt='kuva4' className='image'/>
                </div>
                <div className='img-body'>
                    <img src="./images/galleria-kuva5C.jpg" alt='kuva5' className='image'/>
                </div>
                <div className='img-body'>
                    <img src="./images/galleria-kuva6C.jpg" alt='kuva6' className='image'/>
                </div>
                <div className='img-body'>
                    <img src="./images/galleria-kuva7C.jpg" alt='kuva6' className='image'/>
                </div>
                <div className='img-body'>
                    <img src="./images/galleria-kuva8C.jpg" alt='kuva6' className='image'/>
                </div>
                <div className='img-body'>
                    <img src="./images/galleria-kuva9C.jpg" alt='kuva6' className='image'/>
                </div>
                <div className='img-body'>
                    <img src="./images/galleria-kuva10C.jpg" alt='kuva6' className='image'/>
                </div>
            </Slider>
            </div>
        </div>
    </section>
    );
}

export default Galleria;