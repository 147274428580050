import { FaLongArrowAltLeft } from "react-icons/fa";
import { useEffect } from "react";

const Tietosuoja = () => {
    useEffect(() => {
        document.title = "Tietosuojaseloste - Rakennusrestaurointi Taltta ja Vasara Oy";  
        window.scrollTo(0,0);
    }, []);

    return (
        <section className="wrapper">
            <div className="content" id="tietosuoja">
                <a
                    href="/"
                    className="btn btn_red return"
                >
                    <FaLongArrowAltLeft className="arrow-icon"/>

                    <span>Palaa etusivulle</span>
                </a>

                <div className="laki-teksti">
                    <h2 className="otsikko">
                        Tietosuojaseloste
                    </h2>

                    <p className="teksti">
                        Henkilötietolain (10 ja 24 §) ja EU:n yleisen tietosuoja-asetuksen (GDPR) mukainen rekisteri- ja tietosuojaseloste. Päivitetty 01.03.2024
                    </p>
                </div>

                <div className="laki-teksti">
                    <h3 className="otsikko">
                        1. Yleistä
                    </h3>

                    <p className="teksti">
                        Tämä tietosuojaseloste koskee Rakennusrestaurointi Taltta ja Vasara Oy:n verkkosivustoa. Tässä selosteessa kerromme, miten käsittelemme sivustollamme vierailevien käyttäjien henkilötietoja EU:n ja Suomen lainsäädännön mukaisesti.
                    </p>
                </div>

                <div className="laki-teksti">
                    <h3 className="otsikko">
                        2. Rekisterinpitäjä
                    </h3>

                    <p className="teksti">
                        Kimmo Soiluaho, Rakennusrestaurointi Taltta ja Vasara Oy
                    </p>

                    <p className="teksti">
                        Syrjäntie 10, 90820 Kello
                    </p>

                    <p className="teksti">
                        +358 40 7579 969
                    </p>
                    
                    <p className="teksti">
                        konttori@talttajavasara.fi
                    </p>
                </div>

                <div className="laki-teksti">
                    <h3 className="otsikko">
                        3. Käyttäjän henkilötiedot
                    </h3>

                    <p className="teksti">
                        Emme kerää emmekä tallenna käyttäjiemme henkilötietoja verkkosivustollamme. Emme myöskään käytä evästeitä tai muita seurantatyökaluja.
                    </p>
                </div>

                <div className="laki-teksti">
                    <h3 className="otsikko">
                        4. Evästeet
                    </h3>

                    <p className="teksti">
                        Koska emme käytä evästeitä, sivustomme ei tallenna tietoja kävijöiden laitteille.
                    </p>
                </div>

                <div className="laki-teksti">
                    <h3 className="otsikko">
                        5. Tietojen luovuttaminen
                    </h3>

                    <p className="teksti">
                        Emme luovuta yhteydenotoista keräämiämme tai tallentamiamme tietoja kolmansille osapuolille.
                    </p>
                </div>

                <div className="laki-teksti">
                    <h3 className="otsikko">
                        6. Käyttäjän oikeudet
                    </h3>

                    <p className="teksti">
                        Käyttäjällä on oikeus pyytää tietoa siitä, mitä tietoja meillä on hänestä, sekä pyytää virheellisten tietojen korjaamista tai poistamista. Käyttäjä voi ottaa yhteyttä meihin yllä olevien yhteystietojen avulla.
                    </p>
                </div>

                <div className="laki-teksti">
                    <h3 className="otsikko">
                        7. Tietoturva
                    </h3>

                    <p className="teksti">
                        Ylläpidämme asianmukaisia teknisiä ja organisatorisia toimenpiteitä suojataksemme käyttäjiemme tietoja luvattomalta pääsyltä, vahingossa tapahtuvalta menetykseltä, tuhoutumiselta tai vahingoittumiselta.
                    </p>
                </div>

                <div className="laki-teksti">
                    <h3 className="otsikko">
                        8. Muutokset tietosuojaselosteeseen
                    </h3>

                    <p className="teksti">
                        Pidätämme oikeuden päivittää tätä tietosuojaselostetta tarvittaessa. Suosittelemme käyttäjiämme tarkistamaan tämän sivun säännöllisesti mahdollisten muutosten varalta.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default Tietosuoja;