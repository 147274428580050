import { useState } from 'react';

import './header.scss';

import { slide as Menu } from 'react-burger-menu'

const Header = () => {
	const [ isOpen, setIsOpen ] = useState<boolean>(false);

	const handleIsOpen = () => {
		setIsOpen(!isOpen);
	}

	return (
		<header id="header" className="wrapper">
			<div className="content header-content">
				<img src='./images/logo.svg' alt='Logo' id='logo'></img>
				{/* Tietokone Linkit */}
				<div className="header-links">
					<a className='link' href='#landing'>Koti</a>
					<a className='link' href='#palvelut'>Palvelut</a>
					<a className='link' href='#galleria'>Galleria</a>
					<a className='link' href='#yhteystiedot'>Yhteystiedot</a>

					{/* Puhelin Menu */}
					<Menu
						isOpen={ isOpen }
						noOverlay
						right
						onOpen={handleIsOpen}
						onClose={handleIsOpen}
					>
                		<a
							className="link"
							href="#landing"
							onClick={handleIsOpen}
						>
							Koti
						</a>

                		<a
							className="link"
							href="#palvelut"
							onClick={handleIsOpen}
						>
							Palvelut
						</a>

                		<a
							className="link"
							href="#galleria"
							onClick={handleIsOpen}
						>
							Galleria
						</a>

                		<a
							className="link"
							href="#yhteystiedot"
							onClick={handleIsOpen}
						>
							Yhteystiedot
						</a>
            		</Menu>
				</div>
			</div>
		</header>
	);
}

export default Header;