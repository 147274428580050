import { useEffect } from "react";

import Landing from "./elements/landing";
import Palvelut from "./elements/palvelut";
import Galleria from "./elements/galleria";
import Yhteystiedot from "./elements/yhteystiedot";
import Header from "../../components/header";
import Footer from "../../components/footer";

const Home = () => {
	useEffect(() => {
        document.title = "Rakennusrestaurointi Taltta ja Vasara Oy";  
        window.scrollTo(0,0);
    }, []);

	return (
		<main>
			<Header />
			<Landing />
			<Palvelut />
			<Galleria/>
			<Yhteystiedot/>
			<Footer/>
		</main>
	);
}

export default Home;