import { FaLongArrowAltLeft } from "react-icons/fa";
import { useEffect } from "react";

const Yksityisyyskäytäntö = () => {
    useEffect(() => {
        document.title = "Yksityisyyskäytäntö - Rakennusrestaurointi Taltta ja Vasara Oy";  
        window.scrollTo(0,0);
    }, []);

    return (
        <section className="wrapper">
            <div className="content" id="tietosuoja">
                <a
                    href="/"
                    className="btn btn_red return"
                >
                    <FaLongArrowAltLeft className="arrow-icon"/>

                    <span>Palaa etusivulle</span>
                </a>

                <div className="laki-teksti">
                    <h2 className="otsikko">
                        Yksityisyyskäytäntö
                    </h2>

                    <p className="teksti">
                        Tämä yksityisyyskäytäntö kuvaa, miten sivusto kerää, käyttää ja suojelee henkilökohtaisia tietoja verkkosivustonsa käyttäjiltä. Henkilökohtaisilla tiedoilla tarkoitetaan kaikkia sellaisia tietoja, joista käyttäjät voivat olla tunnistettavissa. Päivitetty 01.03.2024
                    </p>
                </div>

                <div className="laki-teksti">
                    <h3 className="otsikko">
                        1. Kerätyt Tiedot
                    </h3>

                    <p className="teksti">
                        1.1. Kun käyttäjät vierailevat sivustollamme, voimme automaattisesti kerätä tietoja, kuten IP-osoitteet, selaintiedot, käyttöjärjestelmät ja muut verkkoliikennetiedot.
                    </p><br></br>

                    <p className="teksti">
                        1.2. Voimme myös kerätä henkilökohtaisia tietoja, kuten nimi, sähköpostiosoite ja puhelinnumero, kun käyttäjät vapaaehtoisesti täyttävät lomakkeita tai ottavat meihin yhteyttä.
                    </p>
                </div>

                <div className="laki-teksti">
                    <h3 className="otsikko">
                        2. Tietojen Käyttö
                    </h3>

                    <p className="teksti">
                        2.1. Keräämiämme tietoja käytetään palveluidemme tarjoamiseen, sivuston kehittämiseen ja käyttäjäkokemuksen parantamiseen.
                    </p><br></br>

                    <p className="teksti">
                        2.2. Henkilökohtaisia tietoja käytetään vain niihin tarkoituksiin, joista käyttäjät on informoitu ennen tietojen keräämistä tai sellaisiin tarkoituksiin, joihin he ovat antaneet suostumuksensa.
                    </p>
                </div>

                <div className="laki-teksti">
                    <h3 className="otsikko">
                        3. Tietojen Suojelu
                    </h3>

                    <p className="teksti">
                        3.1. Käytämme asianmukaisia fyysisiä, teknisiä ja organisatorisia toimenpiteitä henkilötietojen suojaamiseksi luvattomalta pääsyltä, paljastamiselta, muuttamiselta tai tuhoamiselta.
                    </p><br></br>

                    <p className="teksti">
                        3.2. Pääsy henkilötietoihin on rajoitettu vain niitä tarvitseville henkilöille, ja kaikki työntekijämme sitoutuvat säilyttämään tiedot luottamuksellisina.
                    </p>
                </div>

                <div className="laki-teksti">
                    <h3 className="otsikko">
                        4. Kolmannen Osapuolen Palvelut
                    </h3>

                    <p className="teksti">
                        4.1. Sivustomme saattaa sisältää linkkejä kolmansien osapuolten sivustoille. Näiden sivustojen yksityisyyskäytännöt voivat poiketa omistamme, emmekä ole vastuussa niiden käytännöistä.
                    </p>
                </div>

                <div className="laki-teksti">
                    <h3 className="otsikko">
                        5. Evästeet
                    </h3>

                    <p className="teksti">
                        5.1. Käytämme evästeitä parantaaksemme sivustomme toimivuutta ja tarjotaksemme paremman käyttäjäkokemuksen. Käyttäjät voivat hallita evästeasetuksiaan selaimensa asetuksissa.
                    </p>
                </div>

                <div className="laki-teksti">
                    <h3 className="otsikko">
                        6. Muutokset Yksityisyyskäytäntöön
                    </h3>
                    <p className="teksti">
                        6.1. Tätä yksityisyyskäytäntöä voidaan päivittää ajoittain. Päivitykset astuvat voimaan niiden julkaisuhetkestä alkaen.
                    </p>
                </div>

                <div className="laki-teksti">
                    <h3 className="otsikko">
                        7. Yhteydenotto
                    </h3>
                    <p className="teksti">
                        7.1. Mikäli käyttäjillä on kysymyksiä tai huolia yksityisyyskäytännöstämme, he voivat ottaa meihin yhteyttä osoitteeseen konttori@talttajavasara.fi.
                    </p>
                </div>

                <div className="laki-teksti">
                    <p className="teksti">
                        Kiitos, että tutustuit yksityisyyskäytäntöömme.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Yksityisyyskäytäntö;