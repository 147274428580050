import './palvelut.scss';

const Palvelut = () => {
	return (
		<section id="palvelut" className="wrapper">
			<div className="content">
				<h2
					className="otsikko"
				>
					Palvelut
				</h2>

				<div className="palvelu-grid">
					<div
						className="palvelu"
						data-aos="fade-left"
						data-aos-offset="150"
					>
						<p className="teksti">
							Tarjoamme rakennusten restaurointi- ja entisöintipalveluja pääosin Pohjois-Suomen alueella. Tyypillisiä työkohteitamme ovat rakennusten ikkunat, ovet, ulkovuoraukset, lattiat, katot ja hirsi- sekä muut runkotyöt. Asiakkaamme ovat pääosin julkisen sektorin toimijoita, mutta samalla ammattitaidolla palvelemme myös yksityisasiakkaita.
						</p>

						<img
							src="./images/palvelut_3C.jpg"
							alt="Työmaa"
							className="palvelu-img"
							loading='lazy'
						/>
					</div>

					<div
						className="palvelu"
						data-aos="fade-right"
						data-aos-offset="250"
					>
						<img
							src="./images/palvelu_2C.jpg"
							alt="Työmaa"
							className="palvelu-img"
							loading='lazy'
						/>

						<p className="teksti">
							Työtapamme ja käyttämämme materiaalit kunnioittavat rakennuksen historiaa. Puumateriaalina käytämme kotimaista pohjoisen puutavaraa, joka työstetään tarvittaessa paikallisilla piensahoilla ja -höyläämöissä. Pintakäsittelyt suoritetaan perinteisin menetelmin.
						</p>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Palvelut;