import './yhteystiedot.scss'

import { MdEmail } from "react-icons/md";
import { FaPhone, FaLocationDot, FaFacebook } from "react-icons/fa6";

const Yhteystiedot = () => {
    return (
        <section id="yhteystiedot" className="wrapper">
            <div className="content">
                <h2 className="otsikko">Yhteystiedot</h2>
                <div className="tiedot-row" data-aos= 'fade-down' data-aos-offset="250">
                    <a
                    className="teksti footer-link"
                    href='mailto:konttori@talttajavasara.fi'
                    rel='noreferrer noopener nofollow'
                    target='_blank'
                    >
                        <span><MdEmail className='icon'/> konttori@talttajavasara.fi</span>
                    </a>
                    <a
                    className="teksti footer-link"
                    href='tel:+358407579969'
                    rel='noreferrer noopener nofollow'
                    target='_blank'
                    >
                        <span><FaPhone className='icon'/> +358 40 7579 969</span>
                    </a>
                    <span className='teksti'><FaLocationDot className='icon'/> Syrjäntie 10, 90820 Kello</span>
                </div>
                <h2 className="otsikko">Seuraa Meitä Somessa</h2>
                <div className="tiedot-column" data-aos='fade-down' data-aos-offset="250">
                    <a
                    className='facebook-link'
                   	href='https://www.facebook.com/rakennusrestaurointi/'
                    rel='noreferrer noopener nofollow'
                    target='_blank'
                    >
                        <span className='facebook teksti'>
                            <FaFacebook className='icon'/>
                            Taltta ja Vasara Oy
                        </span>
                    </a>
                    <span className='facebook2 teksti'>Restaurointitöiden edistymistä voi seurata kätevästi Facebook-sivuiltamme.</span>
                </div>
            </div>
        </section>
    )
}

export default Yhteystiedot;