import React from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import AOS from 'aos';
import 'aos/dist/aos.css';

import '../css/App.scss';
import '../css/colors.css';

import Home from './pages/home';
import Tietosuoja from './pages/tietosuoja';
import Käyttöehdot from './pages/käyttöehdot';
import Yksityisyyskäytäntö from './pages/yksityisyyskäytäntö';

function App() {
	AOS.init();

	return (
		<Router>			
			<Routes>
				<Route
					path='/'
					element={<Home/>}
				/>

				<Route
					path='/#*'
					element={<Home/>}
				/>

				<Route
					path='/käyttöehdot'
					element={<Käyttöehdot/>}
				/>

				<Route
					path='/tietosuoja'
					element={<Tietosuoja/>}
				/>

				<Route
					path='/yksityisyyskäytäntö'
					element={<Yksityisyyskäytäntö/>}
				/>
			</Routes>
		</Router>
	);
}

export default App;
