import { FaLongArrowAltLeft } from "react-icons/fa";
import { useEffect } from "react";

const Käyttöehdot = () => {
    useEffect(() => {
        document.title = "Käyttöehdot - Rakennusrestaurointi Taltta ja Vasara Oy";  
        window.scrollTo(0,0);
    }, []);

    return (
        <section className="wrapper">
            <div className="content" id="tietosuoja">
                <a
                    href="/"
                    className="btn btn_red return"
                >
                    <FaLongArrowAltLeft className="arrow-icon"/>

                    <span>Palaa etusivulle</span>
                </a>

                <div className="laki-teksti">
                    <h2 className="otsikko">
                        Käyttöehdot
                    </h2>

                    <p className="teksti">
                        Käyttämällä tätä verkkosivustoa hyväksyt nämä ehdot kokonaisuudessaan. Mikäli et hyväksy näitä ehtoja, pyydämme sinua olemaan käyttämättä tätä sivustoa. Päivitetty 01.03.2024
                    </p>
                </div>

                <div className="laki-teksti">
                    <h3 className="otsikko">
                        1. Sivuston Käyttö
                    </h3>

                    <p className="teksti">
                        1.1. Sivuston sisältö on tarkoitettu yleiseen informaatiotarkoitukseen. Emme takaa tietojen täydellisyyttä tai ajantasaisuutta.
                    </p><br></br>

                    <p className="teksti">
                        1.2. Sivustoa saa käyttää vain laillisiin tarkoituksiin. Kiellettyä on muun muassa sivuston käyttö lainvastaisiin toimintoihin, kuten haittaohjelmien levittämiseen tai epäeettiseen käyttäytymiseen.
                    </p><br></br>

                    <p className="teksti">
                        1.3. Rakennusrestaurointi Taltta ja Vasara Oy pidättää oikeuden muuttaa sivuston sisältöä tai sulkea sivuston ilman erillistä ilmoitusta.
                    </p>
                </div>

                <div className="laki-teksti">
                    <h3 className="otsikko">
                        2. Tekijänoikeudet
                    </h3>

                    <p className="teksti">
                        2.1. Kaikki sivuston sisältö, kuten teksti, kuvat, grafiikat, äänet ja ohjelmistot, ovat Rakennusrestaurointi Taltta ja Vasara Oy:n tai kolmansien osapuolten omistuksessa. Kaikki oikeudet pidätetään.
                    </p><br></br>

                    <p className="teksti">
                        2.2. Sivuston sisältöä ei saa kopioida, levittää tai käyttää kaupallisiin tarkoituksiin ilman etukäteen saatuja kirjallisia lupia.
                    </p>
                </div>

                <div className="laki-teksti">
                    <h3 className="otsikko">
                        3. Vastuunrajoitus
                    </h3>

                    <p className="teksti">
                        3.1. Rakennusrestaurointi Taltta ja Vasara Oy ei ole vastuussa mahdollisista vahingoista, jotka johtuvat sivuston käytöstä tai käytön estymisestä.
                    </p><br></br>

                    <p className="teksti">
                        3.2. Emme takaa sivuston virheettömyyttä emmekä vastaa mahdollisista teknisistä ongelmista tai keskeytyksistä.
                    </p>
                </div>

                <div className="laki-teksti">
                    <h3 className="otsikko">
                        4. Linkit Kolmansien Osapuolten Sivuille
                    </h3>

                    <p className="teksti">
                        4.1. Sivustolla saattaa olla linkkejä kolmansien osapuolten verkkosivuille. Emme vastaa näiden sivujen sisällöstä tai toiminnasta.
                    </p><br></br>

                    <p className="teksti">
                        4.2. Linkit eivät välttämättä merkitse suositusta tai yhteyttä kolmannen osapuolen kanssa.
                    </p>
                </div>

                <div className="laki-teksti">
                    <h3 className="otsikko">
                        5. Muutokset Käyttöehtoihin
                    </h3>

                    <p className="teksti">
                        5.1. Rakennusrestaurointi Taltta ja Vasara Oy voi muuttaa näitä käyttöehtoja ilmoittamatta siitä erikseen. Muutokset astuvat voimaan heti niiden julkaisuhetkestä alkaen.
                    </p>
                </div>

                <div className="laki-teksti">
                    <p className="teksti">
                        Kiitos, että käytät Rakennusrestaurointi Taltta ja Vasara Oy:n verkkosivustoa. Mikäli sinulla on kysyttävää tai huomautettavaa, ota yhteyttä konttori@talttajavasara.fi.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Käyttöehdot;